export enum LibraryStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export enum LibraryType {
  PUBLISHER = "PUBLISHER",
  BRAND = "BRAND",
  NORMAL = "NORMAL",
  DIRECT = "DIRECT",
  B2B = "B2B",
  READERS_CLUB = "READERS_CLUB",
}

export enum PointAction {
  ACCUMULATE_BY_ADMIN = "ACCUMULATE_BY_ADMIN",
  ADD_CONSIGNMENT_ITEM = "ADD_CONSIGNMENT_ITEM",
  ADD_ITEM = "ADD_ITEM",
  ADMIN_DEDUCTION = "ADMIN_DEDUCTION",
  CHARGE = "CHARGE",
  CONTENT_REVIEW = "CONTENT_REVIEW",
  EARLY_RETURN = "EARLY_RETURN",
  EXPIRED = "EXPIRED",
  EXTEND_RENTAL_DURATION = "EXTEND_RENTAL_DURATION",
  EXTEND_RENTAL_CANCELED = "EXTEND_RENTAL_CANCELED",
  ORDER_CANCELED = "ORDER_CANCELED",
  ORDERED = "ORDERED",
  PURCHASED = "PURCHASED",
  RECOMMEND = "RECOMMEND",
  RECOMMENDED_BY = "RECOMMENDED_BY",
  REORDER = "REORDER",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  WELCOME = "WELCOME",
}

export enum DepositAction {
  ADMIN_ACCUMULATION = "ADMIN_ACCUMULATION",
  ADMIN_DEDUCTION = "ADMIN_DEDUCTION",
  CANCEL = "CANCEL",
  CANCEL_CONSIGNMENT_RENT = "CANCEL_CONSIGNMENT_RENT",
  CONSIGNMENT_RENT = "CONSIGNMENT_RENT",
  DIRECT_RENT = "DIRECT_RENT",
  EXTEND_CONSIGNMENT_RENT = "EXTEND_CONSIGNMENT_RENT",
  EXTEND_RENTAL_DURATION = "EXTEND_RENTAL_DURATION",
  OVERDUE = "OVERDUE",
  OVERDUE_CONSIGNMENT_RENT = "OVERDUE_CONSIGNMENT_RENT",
  OVERDUE_PAID = "OVERDUE_PAID",
  PAID = "PAID",
  PARTIAL_CANCEL = "PARTIAL_CANCEL",
  POINT_PURCHASED = "POINT_PURCHASED",
  PURCHASED = "PURCHASED",
  SOLD = "SOLD",
  WITHDRAWAL = "WITHDRAWAL",
  SUBSCRIBED = "SUBSCRIBED",
  BOOK_CONSIGNMENT_REGISTER = "BOOK_CONSIGNMENT_REGISTER",
  BOOK_CONSIGNMENT_RETURN = "BOOK_CONSIGNMENT_RETURN",
  SHARE_LINK = "SHARE_LINK",
  RECOMMENDER_RENT = "RECOMMENDER_RENT",
}

export enum PaymentStatus {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PAID = "PAID",
}

export enum PointDetailKey {
  ORDER_NO = "ORDER_NO",
  LIBRARY_NAME = "LIBRARY_NAME",
  COMMENT = "COMMENT",
}

export enum DepositDetailKey {
  ORDER_NO = "ORDER_NO",
  LIBRARY_NAME = "LIBRARY_NAME",
  COUNT = "COUNT",
  COMMENT = "COMMENT",
}

export enum PaymentDetailKey {
  ORDER_NO = "ORDER_NO",
  LIBRARY_NAME = "LIBRARY_NAME",
  COUNT = "COUNT",
  COMMENT = "COMMENT",
}

export enum DeliveryMethod {
  DIRECT = "DIRECT",
  PARCEL = "PARCEL",
}

export enum OrderCancelStatus {
  CANCELED = "CANCELED",
  NONE = "NONE",
}

export enum ReturnDeliveryStatus {
  COMPLETED = "COMPLETED",
  UNPACKING = "UNPACKING",
  CONFIRMED = "CONFIRMED",
  NONE = "NONE",
  REQUESTED = "REQUESTED",
  STARTED = "STARTED",
}

export enum SendDeliveryStatus {
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  GATHERED = "GATHERED",
  GATHERING = "GATHERING",
  NONE = "NONE",
  STARTED = "STARTED",
}

export enum StockMemoType {
  DAMAGE = "DAMAGE",
  ETC = "ETC",
  SUPPLEMENT = "SUPPLEMENT",
}

export enum OrderItemApprovalStatus {
  APPROVED = "APPROVED",
  NONE = "NONE",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  PICKED = "PICKED",
}

export enum Language {
  ENGLISH = "ENGLISH",
  ETC = "ETC",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  MANDARIN = "MANDARIN",
}

export enum BookInspectionStatus {
  APPROVED = "APPROVED",
  INSPECTING = "INSPECTING",
}

export enum PublishType {
  PERIODICAL = "PERIODICAL",
  SET = "SET",
  SINGLE = "SINGLE",
  BOARD_GAME = "BOARD_GAME",
  WORKBOOK = "WORKBOOK",
}

export enum BookType {
  AUDIO = "AUDIO",
  ELECTRONIC = "ELECTRONIC",
  PAPER = "PAPER",
}

export enum StockStatus {
  CLOSED = "CLOSED",
  LOST = "LOST",
  DISCARDED = "DISCARDED",
  IDLE = "IDLE",
  LENT = "LENT",
  PACKED = "PACKED",
  PENDING_RETURN_CONFIRM = "PENDING_RETURN_CONFIRM",
  RESERVED = "RESERVED",
  RETURN_RESERVED = "RETURN_RESERVED",
  BE_SOLD = "BE_SOLD",
  SOLD = "SOLD",
}

export enum GradeTier {
  BEST = "BEST",
  GOOD = "GOOD",
  BASIC = "BASIC",
}

export enum StockKeepingType {
  LIMITED = "LIMITED",
  UNLIMITED = "UNLIMITED",
}

export enum ImageType {
  LIBRARY = "LIBRARY",
  BANNER = "BANNER",
  EVENT = "EVENT",
  CONTENT = "CONTENT",
  ITEM = "ITEM",
  BOOK = "BOOK",
  HOMETAG = "HOME_TAG",
  DAMAGED = "DAMAGED",
  EDITOR = "EDITOR",
  NOTICE = "NOTICE",
  AFFILIATED_COMPANY = "AFFILIATED_COMPANY",
  PRODUCT = "PRODUCT",
  ETC = "ETC",
}

export enum DamageLevel {
  BEST = "BEST",
  GOOD = "GOOD",
  NOT_GOOD = "NOT_GOOD",
}

export enum ConditionLevel {
  N = "N",
  S = "S",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  F = "F",
  M = "M",
  AT = "AT",
}

export enum BannerType {
  BANNER = "BANNER",
  WOODO = "WOODO",
  POPUP = "POPUP",
}

export enum NotificationType {
  ADD_CONSIGNMENT_ITEM = "ADD_CONSIGNMENT_ITEM",
  ADD_ITEM = "ADD_ITEM",
  ADD_LIBRARY = "ADD_LIBRARY",
  ADD_ORDER_PURCHASED = "ADD_ORDER_PURCHASED",
  ADD_ORDER_SOLD = "ADD_ORDER_SOLD",
  BOOKMARKED = "BOOKMARKED",
  CONSIGNMENT_DEPOSIT = "CONSIGNMENT_DEPOSIT",
  CONTENT_REVIEW_LIKE = "CONTENT_REVIEW_LIKE",
  DELIVERY_APPROVAL = "DELIVERY_APPROVAL",
  DELIVERY_CANCELED = "DELIVERY_CANCELED",
  DELIVERY_COMPLETED = "DELIVERY_COMPLETED",
  DELIVERY_STARTED = "DELIVERY_STARTED",
  EARLY_RETURN_POINT = "EARLY_RETURN_POINT",
  EXPECTED_RETURN_BUYER = "EXPECTED_RETURN_BUYER",
  EXPECTED_RETURN_SELLER = "EXPECTED_RETURN_SELLER",
  EXPIRES_IN_POINT = "EXPIRES_IN_POINT",
  FAVORITE_LIBRARY_ADD_ITEM = "FAVORITE_LIBRARY_ADD_ITEM",
  LIBRARY_LIKE = "LIBRARY_LIKE",
  OMIITED = "OMIITED",
  OMITTED_RETURN_COMPLETED = "OMITTED_RETURN_COMPLETED",
  OVERDUE = "OVERDUE",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  RETURN_COMPLETED_POINT = "RETURN_COMPLETED_POINT",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURN_REQUESTED_POINT = "RETURN_REQUESTED_POINT",
}

export const enum OrderLogAction {
  ADD_ITEM = "ADD_ITEM",
  ADJUST_OMISSION_OVERDUE_DAYS = "ADJUST_OMISSION_OVERDUE_DAYS",
  ADJUST_OVERDUE_DAYS = "ADJUST_OVERDUE_DAYS",
  ADJUST_TOTAL_OVERDUE_FEE = "ADJUST_TOTAL_OVERDUE_FEE",
  CANCEL = "CANCEL",
  CHANGE_DESTINATION = "CHANGE_DESTINATION",
  CHANGE_RENTAL_DAYS = "CHANGE_RENTAL_DAYS",
  CHANGE_RETURN_DELIVERY_CODE = "CHANGE_RETURN_DELIVERY_CODE",
  CHANGE_SEND_DELIVERY_CODE = "CHANGE_SEND_DELIVERY_CODE",
  DELETE = "DELETE",
  DELIVERY_COMPLETED = "DELIVERY_COMPLETED",
  DELIVERY_CONFIRMED = "DELIVERY_CONFIRMED",
  DELIVERY_STARTED = "DELIVERY_STARTED",
  GATHERING_COMPLETED = "GATHERING_COMPLETED",
  PACK_ITEMS = "PACK_ITEMS",
  PAY_OVERDUE_FEE = "PAY_OVERDUE_FEE",
  PURCHASE_RENTED = "PURCHASE_RENTED",
  REJECT = "REJECT",
  REJECT_ITEMS = "REJECT_ITEMS",
  REJECT_RETURN_REQUESTED = "REJECT_RETURN_REQUESTED",
  RESTORE_PACKED_ITEMS = "RESTORE_PACKED_ITEMS",
  RESTORE_REJECTED_ITEMS = "RESTORE_REJECTED_ITEMS",
  RESTORE_RETURN_CONFIRMED_ITEMS = "RESTORE_RETURN_CONFIRMED_ITEMS",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  RETURN_CONFIRM = "RETURN_CONFIRM",
  RETURN_CONFIRM_ITEMS = "RETURN_CONFIRM_ITEMS",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURN_STARTED = "RETURN_STARTED",
  START_GATHERING = "START_GATHERING",
  PICK_ITEMS = "PICK_ITEMS",
  RETURN_UNPACKING = "RETURN_UNPACKING",
}

export const enum SnsType {
  APPLE = "APPLE",
  KAKAO = "KAKAO",
  GOOGLE = "GOOGLE",
}

export const enum AdditionalPaymentType {
  EXTEND_RENTAL_DURATION = "EXTEND_RENTAL_DURATION",
  PAY_OVERDUE_FEE = "PAY_OVERDUE_FEE",
  PURCHASE = "PURCHASE",
}

export const enum AdminRole {
  ADMIN = "ADMIN",
  ADMIN_CANDIDATE = "ADMIN_CANDIDATE",
  SUPER_ADMIN = "SUPER_ADMIN",
  CONTRACTOR = "CONTRACTOR",
}

export const enum AdminStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

export enum SortContent {
  BOOKMARK_COUNT = "BOOKMARK_COUNT",
  KEYWORD_SCORE = "KEYWORD_SCORE",
  PUBLICATION = "PUBLICATION",
  REGISTRATION = "REGISTRATION",
  RENTAL_COUNT = "RENTAL_COUNT",
  REVIEW_COUNT = "REVIEW_COUNT",
  LAST_MONTH_RENTAL_COUNT = "LAST_MONTH_RENTAL_COUNT",
  CUSTOM_RANK = "CUSTOM_RANK",
}

export enum PaymentChannel {
  API = "api",
  MOBILE = "mobile",
}

export enum PgProvider {
  KAKAOPAY = "kakaopay",
  NAVERPAY = "naverpay",
  NICE = "nice",
}

export enum ReceptionType {
  LIBRARY_DELIVERY = "LIBRARY_DELIVERY",
  RETURN_DELIVERY_REQUESTED = "RETURN_DELIVERY_REQUESTED",
  VISIT = "VISIT",
  WOODO_DELIVERY = "WOODO_DELIVERY",
}

export enum ConsignmentRegistrationStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  DELIVERY_RECEPTION = "DELIVERY_RECEPTION",
  DELIVERY_COMPLETED = "DELIVERY_COMPLETED",
  NONE = "NONE",
  REGISTERING = "REGISTERING",
}

export enum ConsignmentRegistrationApprovalStatus {
  NONE = "NONE",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum TagFilter {
  ALL = "ALL",
  KOREAN = "KOREAN",
  ENGLISH = "ENGLISH",
}

export enum Platform {
  APP = "APP",
  WEB = "WEB",
}

export enum AppBannerDisplayPosition {
  MAIN_TOP = "MAIN_TOP",
  SEARCH_TOP = "SEARCH_TOP",
  MAIN_WOODO_TOP = "MAIN_WOODO_TOP",
  MAIN_BOTTOM_SHEET = "MAIN_BOTTOM_SHEET",
  BOOK_DETAIL_MID = "BOOK_DETAIL_MID",
  STOCK_LIST_TOP = "STOCK_LIST_TOP",

  // CLASS CATEGORY HOME
  CLASS_CHILD_TOP = "CLASS_CHILD_TOP",
  CLASS_ELEMENTARY_TOP = "CLASS_ELEMENTARY_TOP",
  CLASS_TEENAGER_TOP = "CLASS_TEENAGER_TOP",
  CLASS_ADULT_TOP = "CLASS_ADULT_TOP",
  CLASS_ENGLISH_TOP = "CLASS_ENGLISH_TOP",
}

export enum WebBannerDisplayPosition {
  MAIN_TOP = "MAIN_TOP",
  MAIN_TOP_SIDE = "MAIN_TOP_SIDE",
  MAIN_MIDDLE_SESSION = "MAIN_MIDDLE_SESSION",
  MAIN_BOTTOM_SESSION = "MAIN_BOTTOM_SESSION",
}

export enum DisplayPlatform {
  ALL = "ALL",
  APP = "APP",
  WEB = "WEB",
}

export enum AvailablePlatformType {
  ALL = "ALL",
  APP = "APP",
  WEB = "WEB",
}

export enum CouponType {
  EVERYONE = "EVERYONE",
  LIMITED_LIBRARY = "LIMITED_LIBRARY",
}

export enum BookStoreRequestReason {
  NEW = "NEW",
  BEST_STEADY_SELLER = "BEST_STEADY_SELLER",
  MUST_READ = "MUST_READ",
  RECOMMEND = "RECOMMEND",
  TEXTBOOK = "TEXTBOOK",
  ETC = "ETC",
}

export enum ContentRecommendStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum BookStoreRequestStatus {
  REQUESTED = "REQUESTED",
  ORDERED = "ORDERED",
  STORED = "STORED",
}

export enum AdminBookSearchSort {
  BOOK_ID_ASC = "BOOK_ID_ASC",
  BOOK_ID_DESC = "BOOK_ID_DESC",
  TITLE_ASC = "TITLE_ASC",
  TITLE_DESC = "TITLE_DESC",
  PUBLISHED_AT_ASC = "PUBLISHED_AT_ASC",
  PUBLISHED_AT_DESC = "PUBLISHED_AT_DESC",
  IDLE_STOCK_ASC = "IDLE_STOCK_ASC",
  IDLE_STOCK_DESC = "IDLE_STOCK_DESC",
}

export enum StockSearchSort {
  PUBLISHED_AT_ASC = "PUBLISHED_AT_ASC",
  PUBLISHED_AT_DESC = "PUBLISHED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
}

export enum OrderMemoType {
  DAMAGE = "DAMAGE",
  ETC = "ETC",
  SUPPLEMENT = "SUPPLEMENT",
}

export enum LibraryMemoType {
  ADDITIONAL_BOOK = "ADDITIONAL_BOOK",
  INSPECTION = "INSPECTION",
  ETC = "ETC",
}

export enum ChangeWoodoSectionStatus {
  NOT_FOUND = "NOT_FOUND",
  CHANGED = "CHANGED",
  NOT_CHANGED = "NOT_CHANGED",
  ERROR = "ERROR",
}

export enum RecommenderRewardType {
  DEPOSIT = "DEPOSIT",
  POINT = "POINT",
}

export enum CertificationHistoryStatus {
  DONE = "DONE",
  PROCEEDING = "PROCEEDING",
  PROGRESS = "PROGRESS",
}

export enum RequestApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
}

export enum SendDeliveryType {
  ORDER = "ORDER",
  RETURN = "RETURN",
  NONMEMBER_ORDER = "NONMEMBER_ORDER",
  EXTERNAL_ORDER = "EXTERNAL_ORDER",
  STORAGE_RETURN = "STORAGE_RETURN",
}

export enum CashChangeAction {
  ADMIN_ACCUMULATION = "ADMIN_ACCUMULATION",
  CANCEL = "CANCEL",
  PARTIAL_CANCEL = "PARTIAL_CANCEL",
  REGULAR_PAYMENT = "REGULAR_PAYMENT",
  ADMIN_DEDUCTION = "ADMIN_DEDUCTION",
  PAID = "PAID",
  POINT_PURCHASED = "POINT_PURCHASED",
  PURCHASED = "PURCHASED",
  EXTEND_RENTAL_DURATION = "EXTEND_RENTAL_DURATION",
  BOOK_CONSIGNMENT_REGISTER = "BOOK_CONSIGNMENT_REGISTER",
  BOOK_CONSIGNMENT_RETURN = "BOOK_CONSIGNMENT_RETURN",
}

export enum CashDetailKey {
  ORDER_NO = "ORDER_NO",
  COMMENT = "COMMENT",
}

export enum PartnerLibraryBannerLanguage {
  KOREAN = "KOREAN",
  ENGLISH = "ENGLISH",
}

export enum AffiliatedCompanyMemberStatus {
  NONE = "NONE",
  OMITTED = "OMITTED",
}

export enum ExternalOrderChannel {
  ALADIN = "ALADIN",
  YES_24 = "YES_24",
}

export enum ReadingNotesType {
  ONE_LINE_SUMMARY = "ONE_LINE_SUMMARY",
  SENTENCES_BOOK = "SENTENCES_BOOK",
  READING_BOOK = "READING_BOOK",
}

export enum MvpOrderStatus {
  NONE = "NONE",
  GATHERING = "GATHERING",
  SEND_STARTED = "SEND_STARTED",
  RENT = "RENT",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURN_STARTED = "RETURN_STARTED",
  INSPECTION_COMPLETED = "INSPECTION_COMPLETED",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}

export enum ShippingCompany {
  SC_01 = "SC_01",
  SC_02 = "SC_02",
  SC_03 = "SC_03",
  SC_04 = "SC_04",
  SC_05 = "SC_05",
}

export enum UsedStockStatus {
  IDLE = "IDLE",
  RESERVED = "RESERVED",
  PACKED = "PACKED",
  LENT = "LENT",
  SOLD = "SOLD",
  DISCARDED = "DISCARDED",
}

export enum MvpOrderAction {
  ORDER_CREATED = "ORDER_CREATED",
  START_GATHERING = "START_GATHERING",
  PACK_ITEMS = "PACK_ITEMS",
  GATHERING_COMPLETED = "GATHERING_COMPLETED",
  REJECT_DELIVERY_STARTED = "REJECT_DELIVERY_STARTED",
  DELIVERY_STARTED = "DELIVERY_STARTED",
  DELIVERY_COMPLETED = "DELIVERY_COMPLETED",
  DELIVERY_CONFIRMED = "DELIVERY_CONFIRMED",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURN_STARTED = "RETURN_STARTED",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  RETURN_UNPACKING = "RETURN_UNPACKING",
  RETURN_CONFIRM = "RETURN_CONFIRM",
  CANCEL = "CANCEL",
  REJECT = "REJECT",
  CHANGE_DESTINATION = "CHANGE_DESTINATION",
  CHANGE_EXTERNAL_INFO = "CHANGE_EXTERNAL_INFO",
  CHANGE_SEND_DELIVERY_CODE = "CHANGE_SEND_DELIVERY_CODE",
  CHANGE_RETURN_DELIVERY_CODE = "CHANGE_RETURN_DELIVERY_CODE",
  CHANGE_REJECT_DELIVERY_CODE = "CHANGE_REJECT_DELIVERY_CODE",
  PAYMENT_FAILED_AND_CANCEL = "PAYMENT_FAILED_AND_CANCEL",
  COMPLETE_ORDER = "COMPLETE_ORDER",
}

export enum ExecutorType {
  SYSTEM = "SYSTEM",
  ADMIN = "ADMIN",
  LIBRARY = "LIBRARY",
  NONMEMBER = "NONMEMBER",
  PAYMENT_LIBRARY = "PAYMENT_LIBRARY",
}

export enum ProductAdditionalFeeStatus {
  NONE = "NONE",
  NEW = "NEW",
  MODIFIED = "MODIFIED",
  DELETED = "DELETED",
}

export enum ExternalStoreType {
  CS = "CS",
  DIRECT = "DIRECT",
  DELIVERY = "DELIVERY",
  REGISTER_CONSIGNMENT = "REGISTER_CONSIGNMENT",
  RENT = "RENT",
}

export enum ExternalExpectStoreBookType {
  CS = "CS",
  DIRECT = "DIRECT",
}

export enum ExternalStoreTargetType {
  ORDER = "ORDER",
  NONMEMBER_ORDER = "NONMEMBER_ORDER",
  EXTERNAL_EXPECT_STORE_BOOK = "EXTERNAL_EXPECT_STORE_BOOK",
}

export enum AdminActionType {
  ORDER_PICK_ITEMS = "ORDER_PICK_ITEMS",
  ORDER_RETURN_CONFIRM_ITEMS = "ORDER_RETURN_CONFIRM_ITEMS",
  ORDER_RETURN_CONFIRM = "ORDER_RETURN_CONFIRM",
  ORDER_GATHERING_COMPLETED = "ORDER_GATHERING_COMPLETED",
  BOOK_CONSIGNMENT_APPROVED_ITEM = "BOOK_CONSIGNMENT_APPROVED_ITEM",
  BOOK_CONSIGNMENT_COMPLETED = "BOOK_CONSIGNMENT_COMPLETED",
}

export enum AdminActionTargetType {
  ORDER = "ORDER",
  BOOK_CONSIGNMENT = "BOOK_CONSIGNMENT",
}

export enum ReturnType {
  RETURN = "RETURN",
  STORAGE_RETURN = "STORAGE_RETURN",
}
